import PropTypes from 'prop-types'
import React from 'react'

import logo from '../images/l6s-icon.png'

const Header = props => (
  <header id="header" style={props.timeout ? { display: 'none' } : {}}>
    <div className="logo">
      <img src={logo} alt="" className="logo" />
    </div>
    <div className="content">
      <div className="inner">
        <h1>WiFi as a Service</h1>
        <p>
          A new approach to high-performance WiFi for workspaces.<br />
          Provided by{' '}
          <a
            onClick={() => {
              props.onOpenArticle('about')
            }}
          >
          lelantos</a> and available
          <br />
          exclusively through{' '}
          <a href="https://esevel.com/best-wifi-for-home-office/">Esevel</a>.
        </p>
        <h2>Design & Build</h2>
        <p>
        We also offer upfront ownership <br />
        of network solutions for any space.<br />
        {' '}
        <a
          onClick={() => {
            props.onOpenArticle('enquire')
          }}
        >
        Talk</a> to us about your Residential WiFi needs.
        </p>
      </div>
    </div>
    <nav>
      <ul>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('intro')
            }}
          >
            Intro
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('work')
            }}
          >
            Work
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('about')
            }}
          >
            About
          </button>
        </li>
        <li>
          <button
            onClick={() => {
              props.onOpenArticle('enquire')
            }}
          >
            Contact
          </button>
        </li>
      </ul>
    </nav>
  </header>
)

Header.propTypes = {
  onOpenArticle: PropTypes.func,
  timeout: PropTypes.bool,
}

export default Header
