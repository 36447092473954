import PropTypes from 'prop-types'
import React from 'react'
import pic01 from '../images/hdr_nanohd2_960x320.jpg'
import pic02 from '../images/hdr_switch_960x320.jpg'
import pic03 from '../images/hdr_rb_960x320.jpg'
import pic04 from '../images/hdr_nanohd_720x320.jpg'
import pic05 from '../images/hdr_nanohd3_960x320.jpg'
import pic06 from '../images/hdr_acm_720x320.jpg'
import pic07 from '../images/hdr_achd_960x320.jpg'
import pic08 from '../images/hdr_nanohd4_720x320.jpg'
import pic09 from '../images/hdr_switch_960x320.jpg'
import pic10 from '../images/hdr_uck_720x320.jpg'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">What We Do</h2>
          <span className="image main">
            <img src={pic01} alt="" />
          </span>
          <h3>Welcome!</h3>
          <p>
            Lelantos is a full-service WiFi solution provider. We cater
            particularly to residences with unique needs such as
            work-from-home studies, home studios, shop-houses, tuition and
            work-shops. We also have expertise with large homes, particularly
            penthouses and landed properties with large outdoor areas.
          </p>
          <p>
            Besides our area of focus we also provide more general solutions
            for any type of residential or commercial space: from dormitories
            to HDB units, offices to clinics. Call us whether you are
            renovating and would like a quote; or facing intermittent trouble,
            we will be able to help!
          </p>
          <h3>WiFi problems at home?</h3>
          <p>
            Your Internet subscription typically comes with everything you
            need to get started - including a WiFi router. If you live in a
            small space, or have it ideally setup so that you work near the
            router, you probably do not need our help! Unlike with offices,
            the equipment provided for home use may be sufficient for your
            needs.
          </p>
          <p>
            However if you live in a slightly larger space or perhaps have
            the Fibre Termination Point in your home located in a corner; or
            are perhaps renovating and would like to properly distribute WiFi
            coverage evenly throughout your home, we can help.
          </p>
          <p>
            It may also be that you now work a lot more from home, and the
            equipment when installed was situated for entertainment in the
            living room rather than your study. Or perhaps over time you
            are starting to notice frequent sound drop outs during your
            video conferences, poor video quality or just poor coverage
            insufficient for your work needs.
          </p>
          <h3>What we do</h3>
          <p>
            We bring our experts in to evaluate your current situation and
            needs, identify ideal equipment placement based on your
            environment and supply high-quality yet unobtrusive equipment
            to be installed either by your contractors or ourselves.
          </p>
          <p>
            <blockquote>
              Good WiFi is primarily three things: High-quality
              equipment, good coverage and proper set up.
            </blockquote>
          </p>
          <h3>Our Equipment</h3>
          <p>
            We use enterprise-grade products from
            {' '}<a href="https://www.ui.com/">Ubiquiti</a>,
            {' '}<a href="https://mikrotik.com/">MikroTik</a> and
            {' '}<a href="https://www.netgate.com/">Netgate</a>. "Enterprise"
            products are usually used by business customers in malls and
            offices. These commercial customers require features that
            benefit home users too, particulary those who are working or
            running a business from home. Some of the features enterprise-
            grade equipment offers, that are not always offered at the
            consumer or even prosumer level are:
          </p>
          <p>
            <ul>
              <li>
                <strong>Robustness</strong> - Built for reliability, to be used 24x7
                commercially where downtime and frequent resets are
                not tolerated.
              </li>
              <li>
                <strong>Multiple Networks</strong> - Allowing separation of
                work, home and guest traffic.
              </li>
              <li>
                <strong>Advanced Firewalls</strong> - Options to block and throttle
                undesirable traffic.
              </li>
              <li>
                <strong>Tunable Radios</strong> - Allows setting and tuning of frequency
                bands for optimum performance.
              </li>
              <li>
                <strong>Low Maintenance</strong> - Automatic and tested updates,
                self-monitoring and alerts.
              </li>
              <li>
                <strong>Unobtrusive Design</strong> - Blends into most environments,
                unlike home gaming router.
              </li>
              <li>
                <strong>Roaming</strong> - Made for congested office wifi environments
                where people also move around.
              </li>
            </ul>
          </p>
          <h3>Our Service</h3>
          <p>
            It is not enough to buy good equipment - our service is what
            ensures the equipment performs in your environment. We begin
            every engagement by understanding your needs and performing
            an on-site assessment. This ensures that we can recommend
            complete solutions for good coverage.
          </p>
          <p>
            We then follow-up with the equipment installation and
            commissioning; this covers burn in testing, configuration
            and support for the devices that you will be using. <em>We
            do not just install equipment on-site - we ensure that it works
            for you!</em>
          </p>
          <h3>Affordable Value</h3>
          <p>
            There is a general opinion that enterprise-grade equipment and
            bespoke consulting is more expensive, and indeed we are not cheap.
            However, our experience and business model allow us to offer
            affordable solutions, and we focus on ensuring that you get
            what you pay for in terms of high performance, no-nonsense
            solutions.
          </p>
          <p>
            We offer our service in two models: WiFi as a Service
            subscriptions through <a href="https://esevel.com/">Esevel</a>,
            and bespoke "Design and Build" solutions for any space. Both
            models include our full suite of service and equipment.
          </p>
          <span className="image main">
            <img src={pic06} alt="" />
          </span>
          {close}
        </article>

        <article
          id="work"
          className={`${this.props.article === 'work' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">What We Do</h2>
          <span className="image main">
            <img src={pic02} alt="" />
          </span>
          <h3>Overview</h3>
          <p>
            We are a one-stop solution for residential, home/office and
            commercial WiFi and Internet connectivity. This page has
            information on our specialised yet affordable "Design and Build"
            WiFi solutions, what to expect and how to engage us. If you are
            interested in our WiFi as a Service packages, please see our
            offerings with {' '}<a href="https://esevel.com/best-wifi-for-home-office/">Esevel</a>.
          </p>
          <h3>What do you provide?</h3>
          <p>
            Everything from survey to hardware; this typically includes
            consulting, equipment, installation, commissioning, documentation
            and support.
          </p>
          <h3>How does it work?</h3>
          <p>
            Customers typically come to us with a problem: poor
            video conference quality or weak signal for smart home devices,
            or an opportunity; perhaps an upcoming home renovation or
            desire to offer connectivity to their guests.
          </p>
          <p>
            Upon receipt of your enquiry we respond to schedule a
            no-obligation email exchange or phone call, sometimes followed
            by a video conference. This helps us understand your needs as
            well as your current wifi environment. During this call we will
            suggest some options and give you some idea of pricing.
          </p>
          <h3>What are your consulting costs?</h3>
          <p>
            Should you wish to proceed we will schedule a site visit where
            our consultant will do an on-site survey, discuss your
            expectations and provide a report with recommendations. We
            charge $250 for this consultation and survey.
          </p>
          <p>
            As part of this consultation we will provide a detailed quotation
            and recommendation, but we are also happy to discuss
            alternative options and self-install solutions. We may also have
            advise for improving your current set up, and in some cases
            (typically with misconfiguration) we are able to solve WiFi
            issues on the spot.
          </p>
          <p>
            After the visit we will followup with a quote for a full-service
            installation so that you may consider your options.
          </p>
          <h3>What does it cost?</h3>
          <p>
             Several options will be presented in the quotation. Total
             costs typically depend on your environment and expectation
             of coverage and throughput - for instance, do you need high
             bandwidth, full signal coverage in the closets and laundry
             area?
          </p>
          <p>
             The most common type of installation is for apartments -
             and these typically run in the $2,000 range, costing less
             (excluding your own contractor works) if you are already
             renovating, and significantly more for high-bandwidth coverage
             to every corner of a multi-level penthouse unit.
          </p>
          <p>
             Landed homes vary significantly and a landed homeowner can
             expect to spend from $2,000 upwards just for WiFi; and typically
             around $5,000 on a 3+ storey terraced home under renovation for
             WiFi coverage and wired LAN in every room. Full-coverage of a
             large bungalow with surrounding outdoor land can cost $20,000
             and beyond, ultimately depending on size and complexity.
          </p>
          <h3>What to expect during the engagement</h3>
          <p>
             We start with floor plans and move on to site visits; We scan
             the WiFi environment to allow consideration for neighbouring
             signals, assess your structural layout, work and living spaces,
             then make recommendations that take into account aesthetic
             considerations.
          </p>
          <p>
             We prefer to work with your contractors during a renovation,
             but can also bring our own for minor surface works. Prior to the
             actual installation we conduct a full equipment test and burn-in,
             followed by an on-site infrastructure test before the actual
             physical installation.
          <p>
          </p>
             We then conduct a full test with you and your devices to
             demonstrate the coverage and speed, as well as the simplicity of
             connecting more devices.
          </p>
          <p>
             Our systems are low-maintenance, upgrade automatically and will
             likely not require your attention ever. Nonetheless we provide
             documentation and basic training so you're able to add new
             devices, troubleshoot and provide specific information should
             you run into issues.
          </p>
          <p>
             We also provide direct on-site warranty support options,
             including options for next-day on-site equipment exchange.
          </p>
          <span className="image main">
            <img src={pic08} alt="" />
          </span>
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About</h2>
          <span className="image main">
            <img src={pic03} alt="" />
          </span>
          <h3>
            Hello!
          </h3>
          <p>
            My name is Alvin and I've been networking computers for
            the last 25 years. I started by duct-taping 10base2 co-ax along
            and under staircases myself and still today run OM3/OM4 fibre
            through risers where I can; and where I can't I work with
            professional contractors to install concealed CAT6A and
            single-mode fibre duct'd and jetted through larger buildings.
          </p>
          <p>
            I started deploying WiFi in Y2K using expensive commercial
            solutions from Nokia and Cisco, as well as hacking up cheaper
            routers to do what I couldn't afford to buy as a license. I
            eventually became known as the wifi guy amongst friends and family,
            and have since done a few residential or small commercial wifi
            installations every year even as I embarked on my professional
            growth as a software engineer, product leader and startup coach.
            Along the way as an entrepreneur I've also had the opportunity to
            network various events (both in the personal sense as well as with
            wifi tech), from hackathons to temporary workshops and classrooms,
            both indoors and outdoors.
          </p>
          <p>
            Over the years I've worked on every type of home from a 1-bedroom
            loft to high-rise penthouses, gardens to good-class
            bungalows. I've also fitted out vehicles and early on in my
            career helped make Singapore's national public WiFi network
            secure (Wireless@SGx! That was me!).
          </p>
          <p>
            Not so long ago I decided finally to professionalise, as more
            and more people have been asking for solutions now that many
            of us work from home. A few of my good friends, themselves the
            wifi guy or girl to their friends, have come on board - turning
            their occasional help with installs into an opportunity to tap
            on our combined expertise.
          </p>
          <p>
            Together we now offer to everyone the same solutions that we'd
            recommend our family and friends so that anyone may be able
            to enjoy fast, secure and stable WiFi whether or not they
            know their own "wifi guy".
          </p>
          <p>
            A great "wifi guy" is always stubborn about solutions though,
            so don't expect us to compromise with cheap equipment or
            slipshod solutions. Our products and service cost more than
            buying a cheap router at the IT store, but you get what you
            pay for: which is quality service and equipment,
            all the way from site survey to on-site installation.
          </p>
          <p>
            The products themselves aren't your typical free routers from the
            telcos, or even expensive "prosumer" pineapples with antennas in
            every direction and a matt black finish that only gamers
            appreciate. Rather, the stuff we recommend not only looks "at home
            in your home", it's also so unobtrusive and reliable that these
            same products are found in the offices of Fortune 500 and other
            multinationals. If you've ever been to one of these offices you've
            probably already seen the equipment that we use, but just never
            noticed it! Best of all, it's also surprisingly affordable.
          </p>
          <p>
            The photos you see throughout these pages are examples of real-life
            installations we've been involved in deploying. We've done so much
            more, but wifi equipment is necessarily photogenic and much of the
            magic is hidden in closets or behind walls. If you're wondering
            about something for your home office or studio, chances are we've
            done it and have a photo to prove it!
          </p>
          <p>
            Get in touch with us through our Enquiry page - we're looking
            forward to combining these awesome products with our expertise in
            computer networking and experience across many different spaces
            to come up with affordable solutions that will make your wifi
            experience seamless again.
          </p>
          <p>
             Challenge us today!
          </p>
          <span className="image main">
            <img src={pic10} alt="" />
          </span>
          {close}
        </article>

        <article
          id="enquire"
          className={`${this.props.article === 'enquire' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">ENQUIRE NOW</h2>
          <form method="post" netlify-honeypot="bot-field" data-netlify="true" action="/" name="contact">
              <input type="hidden" name="bot-field" />
              <input type="hidden" name="form-name" value="contact" />
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          {/*
          <ul className="icons">
            <li>
              <a
                href="https://twitter.com/"
                className="icon fa-twitter"
              >
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a href="https://codebushi.com" className="icon fa-facebook">
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a href="https://codebushi.com" className="icon fa-instagram">
                <span className="label">Instagram</span>
              </a>
            </li>
            <li>
              <a
                href="https://github.com/codebushi/gatsby-starter-dimension"
                className="icon fa-github"
              >
                <span className="label">GitHub</span>
              </a>
            </li>
          </ul>
          */}
          <p>
             You could also drop us an email: <a href="mailto: hello@lelantos.org">hello@lelantos.org</a>
          </p>
          <span className="image main">
            <img src={pic07} alt="" />
          </span>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
